// storybook-check-ignore
import { Text, TextProps } from '@opendoor/bricks-next';

import { markdownToReactElement } from './utils';

type TextTokenProps = Omit<TextProps, 'tag' | 'typography'> & {
  children?: string;
  size?: Exclude<TextProps['typography'], number | undefined>;
  typography?: TextProps['typography'];
};

export const Eyebrow = ({ children, size, ...props }: TextTokenProps) => {
  return (
    <Text
      tag="p"
      typography={size ?? '$bodySmall'}
      fontWeight="$medium"
      display="block"
      color="$borderStateHoverPrimary"
      {...props}
    >
      {children}
    </Text>
  );
};

export const Subhead = ({ children, ...props }: TextTokenProps) => {
  return (
    <Text
      tag="h4"
      display="block"
      typography="$bodyLarge"
      fontWeight="$medium"
      color={'$contentPrimary'}
      {...props}
    >
      {children}
    </Text>
  );
};

export const SubheadSmall = ({ children, ...props }: TextTokenProps) => {
  return (
    <Text
      tag="h3"
      typography="$bodyLarge"
      $smallerThanLG={{
        typography: '$bodyMedium',
        fontWeight: '$medium',
      }}
      fontWeight="$medium"
      color={'$contentPrimary'}
      display="block"
      {...props}
    >
      {children}
    </Text>
  );
};

interface TitleWithAccentProps extends TextTokenProps {
  title?: string;
  titleAccent?: string;
  titleSize?: any;
  titleColor?: TextProps['color'];
  titleAccentColor?: TextProps['color'];
  breakTitles?: boolean;
  overRideTitleTag?: 'h1' | 'h2' | 'h3' | undefined;
}

export const TitleWithAccent = ({
  title,
  titleAccent,
  titleSize,
  titleColor,
  titleAccentColor,
  breakTitles,
  overRideTitleTag,
  ...props
}: TitleWithAccentProps) => {
  const typography = {
    typography:
      titleSize === 'Large' ? '$headerSmall' : ('$headerMedium' as TextProps['typography']),
    $largerThanSM: {
      typography: titleSize === 'Large' ? '$displaySmall' : '$headerMedium',
    } as TextProps['$largerThanSM'],
    $largerThanMD: {
      typography: titleSize === 'Large' ? '$displaySmall' : '$headerMedium',
    } as TextProps['$largerThanMD'],
  };

  return (
    <Text
      tag={overRideTitleTag || 'h2'}
      {...typography}
      display="block"
      maxWidth={'16ch' as unknown as number}
      color={titleColor || '$contentPrimary'}
      mb={'$8x'}
      {...props}
      whiteSpace="normal"
    >
      {markdownToReactElement(title || '')}
      {breakTitles && <br />}
      {titleAccent && (
        <Text
          tag="span"
          {...typography}
          color={titleAccentColor || '$contentTertiary'}
          {...props}
          whiteSpace="normal"
        >
          {' '}
          {markdownToReactElement(titleAccent)}
        </Text>
      )}
    </Text>
  );
};

export const Desc = ({ children, ...props }: TextTokenProps) => {
  if (!children) return null;

  return (
    <Text tag="p" color="$contentPrimary" typography="$bodyLarge" display="block" {...props}>
      {markdownToReactElement(children as string)}
    </Text>
  );
};
