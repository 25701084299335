/* storybook-check-ignore */
import React, { useState } from 'react';

import styled from '@emotion/styled';
import { Box, Flex, FlexProps, Text } from '@opendoor/bricks/core';
import Icon from '@opendoor/bricks/core/Icon/Icon';
import { HTMLBricksProps } from '@opendoor/bricks/system/bricks.types';

import { useObservability } from '../../helpers/observability';

const AccordionStyled = styled(Box)`
  & :focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

export type AccordionProps = {
  title: JSX.Element | string;
  body: JSX.Element | string;
  startExpanded?: boolean;
  justifyContent?: FlexProps['justifyContent'];
  // panelId will eventually be required
  panelId?: string;
  /** Optional callback to call when the accordion is opened. Ideal for trackEvent analytics */
  onOpen?: () => void;
  // toggles maxWidth in body
  hasUnboundedWidth?: boolean;
  styles?: Omit<FlexProps['style'], 'color'>;
};

type AccordionElementProps = HTMLBricksProps<'div'> & {
  isOpen: boolean;
  panelId?: string;
};

type AccordionButtonProps = HTMLBricksProps<'div'> & {
  justifyContent?: FlexProps['justifyContent'];
  onClick: () => void;
  isOpen: boolean;
  panelId?: string;
};

/** Accordion component that has the ability to open for additional info or close for less info. This component is used by AccordionList which stacks several Accordions together */
export const Accordion: React.FC<AccordionProps> = ({
  title,
  body,
  startExpanded = false,
  justifyContent,
  // Each accordion button has a unique id associated with its aria-controls (each button controls this particular id which references the hidden content beneath it).
  // Id must be unique for all accordions on the page this associates the button to the hidden content underneath
  // http://web-accessibility.carnegiemuseums.org/code/accordions/
  panelId,
  onOpen,
  hasUnboundedWidth = false,
  styles,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(startExpanded);
  const { trackEvent } = useObservability();
  /**
   * Handles opening the accordion when button is clicked
   * Also calls the onOpen callback (if provided) when we're opening the accordion
   */
  const handleClick = () => {
    if (onOpen && !isOpen) {
      onOpen();
    }
    if (!isOpen) {
      trackEvent('cta-click', panelId, undefined);
    }
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <AccordionStyled
      data-testid="accordion"
      width="100%"
      padding={['16px 0', null, '24px 0']}
      {...styles}
    >
      <AccordionButton
        onClick={handleClick}
        justifyContent={justifyContent}
        isOpen={isOpen}
        panelId={panelId}
        paddingLeft={[0, 0, 'initial']}
      >
        {typeof title === 'string' ? (
          <Text
            fontSize={['24px', null, '22px']}
            // @ts-expect-error TODO - @growth to resolve
            lineHeight={'33px'}
            fontWeight="semibold"
            textAlign={'left'}
            maxWidth={['266px', '100%', '555px']}
          >
            {title}
          </Text>
        ) : (
          title
        )}
        <AccordionIcon isOpen={isOpen} />
      </AccordionButton>
      <AccordionPanel isOpen={isOpen} panelId={panelId}>
        {typeof body === 'string' ? (
          <Text fontSize={'s1'} whiteSpace="pre-line" color="neutrals90">
            {body}
          </Text>
        ) : (
          <Box maxWidth={hasUnboundedWidth ? '100%' : '569px'}>{body}</Box>
        )}
      </AccordionPanel>
    </AccordionStyled>
  );
};

/** Accordion button that handles if the accordion is open or close */
export const AccordionButton: React.FC<AccordionButtonProps> = ({
  children,
  onClick,
  justifyContent,
  isOpen,
  panelId,
  ...rest
}) => (
  <Flex
    as="button"
    alignItems="center"
    cursor="pointer"
    onClick={onClick}
    outline="0"
    width="100%"
    justifyContent={justifyContent ?? 'space-between'}
    data-testid="accordion-button"
    // Blank aria-label because the question should be descriptive enough
    aria-label=""
    textAlign="start"
    // accordion controls value is equal the ID of the tag that wraps the expanded content
    aria-controls={panelId}
    // set on an element to indicate if a control is expanded or collapsed
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-expanded
    aria-expanded={isOpen}
    // {border: none and background: none} are originally defined in Primer.
    // Although bricks still depends on Primer in some ways, it should be decoupled.
    border="none"
    background="none"
    {...rest}
  >
    {children}
  </Flex>
);

/** Chevron icon used inside Accordion Button that users can click to open or close accordion */
export const AccordionIcon: React.FC<AccordionElementProps> = ({
  isOpen,
  justifyContent,
  ...rest
}) => {
  const iconStyles = {
    transform: isOpen ? 'rotate(180deg)' : undefined,
    transition: 'transform 0.2s',
    justifyContent: justifyContent ?? 'flex-end',
    transformOrigin: 'center',
  };

  return (
    <Flex mx={3} alignItems="center" data-testid="accordion-icon" sx={iconStyles} {...rest}>
      <Icon name="chevron-down" color="neutrals100" size={16} />
    </Flex>
  );
};

/** TODO: Panel is visable when isOpen is true */
export const AccordionPanel: React.FC<AccordionElementProps> = ({ children, isOpen, panelId }) => {
  return (
    <Box
      height="auto"
      maxHeight={isOpen ? '875px' : '0'}
      mt={isOpen ? 3 : 0}
      paddingLeft={[3, 0]}
      opacity={isOpen ? 1 : 0}
      overflow="hidden"
      transition="all 0.3s ease-out"
      data-testid="accordion-panel"
      // Hide from screen-readers while closed
      aria-hidden={!isOpen}
      id={panelId}
    >
      {children}
    </Box>
  );
};
