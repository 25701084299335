// storybook-check-ignore
import React from 'react';

import { css } from '@emotion/react';
import { getCSSVariable } from '@opendoor/bricks-next';
import { Box, BoxProps, Link, LinkProps } from '@opendoor/bricks/core';

import { defaultEaseCss } from './motion/MotionVariants';
import { IBNComponentThemeConfig } from './styles/ComponentTheme';

export type ICtaStyles = 'Primary' | 'Secondary' | 'Tertiary' | 'Text';

interface LPLinkProps extends LinkProps {
  componentTheme?: any;
  bnTheme?: IBNComponentThemeConfig;
  ctaStyle?: ICtaStyles;
  ctaUrl?: string;
  ctaDisplayText: string;
  ref?: React.RefObject<HTMLAnchorElement>;
}

interface LPFauxBtnProps extends BoxProps {
  componentTheme?: any;
  ctaStyle?: ICtaStyles;
  ctaDisplayText: string;
}

// Tell the button which keys from the component theme to use
// based on the variant name.
const getBtnTheme = (variant: ICtaStyles) => {
  const theme = {
    Primary: {
      bg: 'buttonBg',
      color: 'buttonColor',
      hover: 'buttonHoverBg',
      active: 'buttonActiveBg',
    },
    Secondary: {
      bg: 'secondaryButtonBg',
      color: 'secondaryButtonColor',
      hover: 'secondaryButtonHoverBg',
      active: 'secondaryButtonActiveBg',
    },
    Tertiary: {
      bg: 'tertiaryButtonBg',
      color: 'tertiaryButtonColor',
      hover: 'tertiaryButtonHoverColor',
      active: 'tertiaryButtonActiveColor',
    },
    Text: {
      bg: 'transparent',
      color: 'linkColor',
      hover: 'linkHoverColor',
      active: 'linkColor',
    },
  };

  return {
    bg: theme[variant].bg,
    color: theme[variant].color,
    hover: theme[variant].hover,
    active: theme[variant].active,
  };
};

// TODO: Remove component theme once bnTheme is completely implemented
export const LPLink = ({
  componentTheme,
  bnTheme,
  ctaStyle = 'Primary',
  ctaUrl,
  ctaDisplayText,
  ref,
  ...props
}: LPLinkProps) => {
  const btnTheme = getBtnTheme(ctaStyle);

  const getColorValue = (bnColorToken: any) => getCSSVariable(bnColorToken, 'color');

  const backgroundColor: any = bnTheme
    ? getColorValue(bnTheme.bgAccentBrand)
    : componentTheme
    ? componentTheme[btnTheme.bg]
    : 'brandBlue600';
  const color: any = bnTheme
    ? getColorValue(bnTheme.buttonLabelPrimary)
    : componentTheme
    ? componentTheme[btnTheme.color]
    : 'white0';
  const hoverBg: any = bnTheme
    ? getColorValue(bnTheme.bgHoverAccentBrand)
    : componentTheme
    ? componentTheme[btnTheme.hover]
    : 'white0';
  const activeBg: any = bnTheme
    ? getColorValue(bnTheme.bgHoverAccentBrand)
    : componentTheme
    ? componentTheme[btnTheme.active]
    : 'white0';

  return ctaStyle === 'Text' ? (
    <Link
      color={componentTheme?.linkColor ?? getColorValue(bnTheme?.linkColor)}
      cursor="pointer"
      display="inline-flex"
      fontSize="16px"
      fontWeight="medium"
      href={ctaUrl}
      letterSpacing="-1.4"
      mt={7}
      ref={ref}
      {...props}
      _hover={{
        color: componentTheme?.linkHoverColor ?? getColorValue(bnTheme?.linkHover),
      }}
      _active={{
        color: componentTheme?.linkColor ?? getColorValue(bnTheme?.linkHover),
      }}
    >
      {ctaDisplayText}
    </Link>
  ) : (
    <Link
      backgroundColor={backgroundColor}
      borderRadius="semiRounded"
      color={color}
      cursor="pointer"
      href={ctaUrl}
      display="inline-flex"
      fontSize="16px"
      fontWeight="medium"
      letterSpacing="-1.4"
      overflow="hidden"
      position="relative"
      p="15px 25px"
      mt={7}
      width="auto"
      ref={ref}
      _after={{
        aspectRatio: '1 / 1',
        backgroundColor: hoverBg,
        content: '""',
        display: 'block',
        borderRadius: '99em 99em 10em 10em',
        left: '0',
        position: 'absolute',
        top: '100%',
        transform: 'scale(0.5)',
        transition: `0.5s ${defaultEaseCss} transform`,
        width: '100%',
      }}
      _hover={{
        color,
        backgroundColor,
      }}
      _focusVisible={{
        color,
        backgroundColor: hoverBg,
      }}
      _active={{
        backgroundColor: activeBg,
      }}
      css={css`
        transition: 0.3s ${defaultEaseCss} background-color;
        will-change: transform;

        &:after {
          will-change: transform;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover:after {
            transform: translate3d(0, -100%, 0) scale(2);
          }
        }
      `}
      {...props}
    >
      <Box as="span" color="inherit" zIndex="5">
        {ctaDisplayText}
      </Box>
    </Link>
  );
};

export const LPFauxBtn = ({
  componentTheme,
  ctaStyle = 'Primary',
  ctaDisplayText,
  ...props
}: LPFauxBtnProps) => {
  const btnTheme = getBtnTheme(ctaStyle);
  const backgroundColor: any = componentTheme ? componentTheme[btnTheme.bg] : 'brandBlue600';
  const color: any = componentTheme ? componentTheme[btnTheme.color] : 'white0';
  const hoverBg: any = componentTheme ? componentTheme[btnTheme.hover] : 'blue700';
  const activeBg: any = componentTheme ? componentTheme[btnTheme.active] : 'blue700';

  return ctaStyle === 'Text' ? (
    <Box
      as="span"
      color={componentTheme.linkColor}
      cursor="pointer"
      display="inline-flex"
      fontSize="16px"
      fontWeight="medium"
      letterSpacing="-1.4"
      mt="32px"
      {...props}
      _hover={{
        color: componentTheme.linkHoverColor,
      }}
      _active={{
        color: componentTheme.linkColor,
      }}
    >
      {ctaDisplayText}
    </Box>
  ) : (
    <Box
      as="span"
      backgroundColor={backgroundColor}
      borderRadius="semiRounded"
      color={color}
      cursor="pointer"
      display="inline-flex"
      fontSize="16px"
      fontWeight="medium"
      letterSpacing="-1.4"
      overflow="hidden"
      position="relative"
      p="15px 25px"
      mt="32px"
      width="auto"
      _after={{
        aspectRatio: '1 / 1',
        backgroundColor: hoverBg,
        content: '""',
        display: 'block',
        borderRadius: '99em 99em 10em 10em',
        left: '0',
        position: 'absolute',
        top: '100%',
        transform: 'scale(0.5)',
        transition: `0.5s ${defaultEaseCss} transform`,
        width: '100%',
      }}
      _hover={{
        color,
        backgroundColor,
      }}
      _focusVisible={{
        color,
        backgroundColor: hoverBg,
      }}
      _active={{
        backgroundColor: activeBg,
      }}
      css={css`
        transition: 0.3s ${defaultEaseCss} background-color;
        will-change: transform;

        &:after {
          will-change: transform;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover:after {
            transform: translate3d(0, -100%, 0) scale(2);
          }
        }
      `}
      {...props}
    >
      <Box as="span" color="inherit" zIndex="5">
        {ctaDisplayText}
      </Box>
    </Box>
  );
};
