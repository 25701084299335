// storybook-check-ignore
import { Box, Image } from '@opendoor/bricks/core';

const SocialIcon: React.FC<{ socialPlatform: string }> = ({ socialPlatform }) => {
  return (
    <Box height="20px" width="20px">
      <Image
        alt={`Social Icon for ${socialPlatform}`}
        loading="lazy"
        src={`https://images.opendoor.com/source/s3/imgdrop-production/${socialPlatform}-icon.svg?preset=square-2048`}
        objectFit="cover"
      />
    </Box>
  );
};

export default SocialIcon;
