import { css } from '@emotion/react';
import { Box } from '@opendoor/bricks/core';

import { ICardListProps } from '../../landing-pages-v2/Multicard';
import { Gutter } from '../../landing-pages-v2/shared/styles/SharedStyles';
import MulticardCard, { IMulticardCardDetails } from './MulticardCard';

const MulticardList = ({
  cards,
  componentTheme,
  columnNumber,
  isArched,
  isAccordion,
  minHeight,
  cardVerticalAlignment,
}: ICardListProps<IMulticardCardDetails>) => (
  <Box
    as="ul"
    m="0"
    p="0"
    role="list"
    display="grid"
    gap={Gutter}
    gridTemplateColumns={[null, null, 'repeat(2, 1fr)', `repeat(${columnNumber}, 1fr)`]}
    css={css`
      list-style: none;
    `}
  >
    {cards?.map((card, idx) => (
      <MulticardCard
        card={card}
        id={`${idx}`}
        key={idx}
        componentTheme={componentTheme}
        isArched={isArched}
        isAccordion={isAccordion}
        minHeight={minHeight}
        cardVerticalAlignment={cardVerticalAlignment}
        columnNumber={columnNumber}
      />
    ))}
  </Box>
);

export default MulticardList;
