/* storybook-check-ignore */
import { Box, Text, TextProps } from '@opendoor/bricks-next';
import 'swiper/css';
import 'swiper/css/pagination';

import { ILpComponentMulticardFields } from 'declarations/contentful';

import { Desc, Eyebrow } from '../landing-pages-v2/shared/Typography';
import { markdownToReactElement } from '../landing-pages-v2/shared/utils';

type TextTokenProps = Omit<TextProps, 'tag' | 'typography'> & {
  children?: string;
  size?: Exclude<TextProps['typography'], number | undefined>;
};
type TypographyConfig = {
  typography: TypographyTokens;
  $largerThanSM: { typography: TypographyTokens };
  $largerThanMD: { typography: TypographyTokens };
};
interface TitleWithAccentProps extends TextTokenProps {
  title?: string;
  titleAccent?: string;
  titleSize?: any;
  titleColor?: TextProps['color'];
  titleAccentColor?: TextProps['color'];
  breakTitles?: boolean;
  typography: TypographyConfig;
}
interface TypographyTokens {
  title: TextProps['typography'];
  subheader: TextProps['typography'];
}
type ScreenSize = 'default' | 'largerThanSM' | 'largerThanMD';

function getTypographyTokens(
  screenSize: ScreenSize,
  titleSize: CardSectionHeaderProps['titleSize'],
  display?: CardSectionHeaderProps['display'],
): TypographyTokens {
  switch (screenSize) {
    case 'largerThanMD':
      if (titleSize === 'Default' && display === 'carousel')
        return { title: '$headerMedium', subheader: '$bodyLarge' };
      if (titleSize === 'Large' && display === 'carousel')
        return { title: '$displaySmall', subheader: '$bodyLarge' };
      if (titleSize === 'Default' && display === 'list')
        return { title: '$headerMedium', subheader: '$bodyLarge' };
      if (titleSize === 'Large' && display === 'list')
        return { title: '$displaySmall', subheader: '$bodyLarge' };
      break;

    case 'largerThanSM':
      if (titleSize === 'Default' && display === 'carousel')
        return { title: '$headerMedium', subheader: '$bodyLarge' };
      if (titleSize === 'Large' && display === 'carousel')
        return { title: '$displaySmall', subheader: '$bodyLarge' };
      if (titleSize === 'Default' && display === 'list')
        return { title: '$headerMedium', subheader: '$bodyLarge' };
      if (titleSize === 'Large' && display === 'list')
        return { title: '$displaySmall', subheader: '$bodyLarge' };
      break;
    default:
      if (titleSize === 'Default' && display === 'carousel')
        return { title: '$headerSmall', subheader: '$bodyLarge' };
      if (titleSize === 'Large' && display === 'carousel')
        return { title: '$headerMedium', subheader: '$bodyLarge' };
      if (titleSize === 'Default' && display === 'list')
        return { title: '$headerSmall', subheader: '$bodyLarge' };
      if (titleSize === 'Large' && display === 'list')
        return { title: '$headerMedium', subheader: '$bodyLarge' };
      return { title: '$headerMedium', subheader: '$bodyLarge' };
  }
  return { title: '$headerMedium', subheader: '$bodyLarge' };
}
const TitleWithAccent = ({
  title,
  titleAccent,
  titleSize,
  titleColor,
  titleAccentColor,
  breakTitles,
  display,
  typography,
  ...props
}: TitleWithAccentProps) => {
  const titleTypographyTokens = {
    typography: typography.typography.title,
    $largerThanSM: { typography: typography.$largerThanSM.typography.title },
    $largerThanMD: { typography: typography.$largerThanMD.typography.title },
  };

  return (
    <Text
      tag="h2"
      {...titleTypographyTokens}
      display="block"
      maxWidth={'16ch' as unknown as number}
      color={titleColor || '$contentPrimary'}
      mb={'$8x'}
      {...props}
      whiteSpace="normal"
    >
      {markdownToReactElement(title || '')}
      {breakTitles && <br />}
      {titleAccent && (
        <Text
          tag="span"
          {...titleTypographyTokens}
          color={titleAccentColor || '$contentTertiary'}
          {...props}
          whiteSpace="normal"
        >
          {' '}
          {markdownToReactElement(titleAccent)}
        </Text>
      )}
    </Text>
  );
};

type CardSectionHeaderProps = Pick<
  ILpComponentMulticardFields,
  'eyebrow' | 'title' | 'subhead' | 'desc' | 'titleAccent' | 'titleSize' | 'display'
>;

export const CardSectionHeader = (fields: CardSectionHeaderProps) => {
  const { titleSize, display } = fields;
  const typography = {
    typography: getTypographyTokens('default', titleSize, display),
    $largerThanSM: { typography: getTypographyTokens('largerThanSM', titleSize, display) },
    $largerThanMD: { typography: getTypographyTokens('largerThanMD', titleSize, display) },
  };
  const subHeadTypographyTokens = {
    typography: typography.typography.subheader,
    $largerThanSM: { typography: typography.$largerThanSM.typography.subheader },
    $largerThanMD: { typography: typography.$largerThanMD.typography.subheader },
  };
  return (
    <Box gap={'$16x'} marginBottom={'$16x'} maxWidth={'$744x'}>
      {fields.eyebrow && <Eyebrow>{fields.eyebrow}</Eyebrow>}
      {fields.title && (
        <TitleWithAccent
          title={fields.title}
          titleAccent={fields.titleAccent}
          titleSize={fields.titleSize}
          typography={typography}
        />
      )}
      {(fields.subhead || fields.desc) && (
        <Box gap="$4x">
          {fields.subhead && (
            <Text
              tag="h4"
              display="block"
              {...subHeadTypographyTokens}
              fontWeight="$medium"
              color={'$contentPrimary'}
            >
              {fields.subhead}
            </Text>
          )}
          {fields.desc && (
            <Desc
              color="$contentPrimary"
              $largerThanMD={{ fontSize: fields.titleSize === 'Large' ? 24 : 'unset' }}
            >
              {fields.desc}
            </Desc>
          )}
        </Box>
      )}
    </Box>
  );
};
