export const getSocialLink = (
  platform: 'twitter' | 'instagram' | 'linkedin' | 'facebook',
  userName: string,
) => {
  // check if userName is URL
  if (userName.startsWith('https://') || userName.startsWith('http://')) {
    return userName;
  }
  // remove prefix of '@' or space
  const cleanUserName = userName.replace(/^[@\s]/, '');

  if (platform === 'twitter') {
    // remove @
    return `https://www.twitter.com/${cleanUserName}`;
  } else if (platform === 'instagram') {
    return `https://www.instagram.com/${cleanUserName}`;
  } else if (platform === 'linkedin') {
    return `https://www.linkedin.com/in/${cleanUserName}`;
  } else if (platform === 'facebook') {
    return `https://www.facebook.com/${cleanUserName}`;
  }
  return '';
};
