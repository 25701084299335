// storybook-check-ignore
import { Box, BoxProps } from '@opendoor/bricks-next';

interface ContainerProps extends BoxProps {
  children: React.ReactNode;
}

const Container = ({ children, ...props }: ContainerProps) => (
  <Box className="lp-section">
    <Box
      display="block"
      maxWidth={1440}
      paddingHorizontal="$10x"
      $largerThanSM={{ paddingHorizontal: '$20x' }}
      $largerThanMD={{ paddingHorizontal: '$32x' }}
      width="100%"
      marginHorizontal="auto"
      {...props}
    >
      {children}
    </Box>
  </Box>
);

const NetProceedsCalculatorContainer = ({ children, ...props }: ContainerProps) => (
  <Box
    display="block"
    maxWidth={1440}
    paddingHorizontal="$10x"
    $largerThanSM={{ paddingHorizontal: '$20x' }}
    $largerThanMD={{ paddingHorizontal: '$32x' }}
    width="100%"
    marginHorizontal="auto"
    {...props}
  >
    {children}
  </Box>
);

const LandingPageSectionContainer = ({ children, ...props }: ContainerProps) => (
  <Box
    marginTop="$16x"
    display="block"
    maxWidth={1440}
    paddingHorizontal="$10x"
    $largerThanSM={{ paddingHorizontal: '$20x' }}
    $largerThanMD={{ paddingHorizontal: '$32x' }}
    width="100%"
    marginHorizontal="auto"
    {...props}
  >
    {children}
  </Box>
);

export { LandingPageSectionContainer, NetProceedsCalculatorContainer };

export default Container;
