import parser from 'react-html-parser';

import markdownIt from 'markdown-it';

const markdownItOptions = {
  html: true,
  breaks: true,
  linkify: true,
  typographer: true,
};

/**
 * Converts a Markdown string to one or more React elements
 * @param {string} markdown
 * @param {boolean} [allowHtml=false] - whether to allow HTML in source content
 * @param {string} [strip=false] - whether to strip a tag from the output
 * @return {HTMLElement}
 */
export const markdownToReactElement = (markdown: string, allowHtml = true) => {
  markdownItOptions.html = allowHtml;

  const html = markdownIt(markdownItOptions).renderInline(markdown);

  return parser(html);
};

export const contentfulToImagesWorkerUrl = (contentfulURL: string) => {
  const contentfulUrl = new URL('https:' + contentfulURL);
  const imagesUrl = new URL('https://images.opendoor.com');
  imagesUrl.pathname = `source/contentful${contentfulUrl.pathname}`;
  return imagesUrl.toString();
};
