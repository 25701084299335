import * as contentful from 'contentful';
import { Entry } from 'contentful';

import { DEPLOY_ENV } from 'components/globals';

export type CollectionProps = {
  total: number;
  items: Array<Entry<any>>;
};

// guards against an unset DEPLOY_ENV variable or a mistakenly
// named DEPLOY_ENV variable (oopsproduction)
// We'll fallback to standard environment if we're not explicitly
// in a non-production environment
// Later this function can take the request context if we want to
// do cookie-based previews
const checkIfPreview = () => {
  return DEPLOY_ENV && ['staging', 'development', 'qa', 'test'].includes(DEPLOY_ENV);
};

export const createContentfulClient = () => {
  const isPreview = checkIfPreview();

  let contentfulClientOpts: contentful.CreateClientParams | null = null;

  if (isPreview) {
    contentfulClientOpts = {
      accessToken: process.env.CONTENTFUL_DELIVERY_PREVIEW_ACCESS_TOKEN!,
      host: 'preview.contentful.com',
      space: process.env.CONTENTFUL_SPACE_ID!,
      environment: process.env.CONTENTFUL_ENVIRONMENT!,
    };
  } else {
    contentfulClientOpts = {
      accessToken: process.env.CONTENTFUL_DELIVERY_ACCESS_TOKEN!,
      space: process.env.CONTENTFUL_SPACE_ID!,
    };
  }
  return contentful.createClient(contentfulClientOpts);
};

export async function fetchEntriesByTags<T>(
  contentType: string,
  tags: Array<string> | undefined,
  options: {
    order?: string;
    limit?: number;
    select?: string;
    skip?: number;
    include?: number;
  } = {},
): Promise<CollectionProps | null> {
  if (tags === undefined) {
    return {
      total: 0,
      items: [],
    };
  }
  const { limit, select, skip, order, include = 2 } = options;
  const client = createContentfulClient();
  const response = await client.getEntries<T>({
    content_type: contentType,
    include,
    'metadata.tags.sys.id[in]': tags.join(','),
    ...(order && { order }),
    ...(select && { select }),
    ...(limit && { limit }),
    ...(skip && { skip }),
  });

  if (response.total === 0) {
    return {
      total: 0,
      items: [],
    };
  }
  const safeEntry = JSON.parse(response.stringifySafe()) as Omit<
    typeof response,
    'toPlainObject' | 'stringifySafe'
  >;

  return {
    total: safeEntry.total,
    items: safeEntry.items,
  };
}

export async function fetchArticlesByTags<IArticleLite>(
  tags: Array<string> | undefined,
  options: {
    limit?: number;
    select?: string;
    skip?: number;
  } = {},
): Promise<CollectionProps | null> {
  return fetchEntriesByTags<IArticleLite>('article', tags, {
    ...options,
    order: '-fields.publishDate',
  });
}

export async function fetchEntryById<T>(
  content_type: string,
  id: string,
  options: {
    select?: string;
    include?: number;
  } = {},
) {
  const { select, include } = options;
  const client = createContentfulClient();
  const response = await client.getEntries<T>({
    content_type,
    ...(select && { select }),
    ...(include && { include }),
    'sys.id[in]': `${id}`,
  });
  if (response.items.length === 0) {
    return undefined;
  }
  const safeEntry = JSON.parse(response.stringifySafe()) as Omit<
    typeof response,
    'toPlainObject' | 'stringifySafe'
  >;
  const entry = safeEntry.items[0];
  return entry;
}

export async function fetchEntriesById<T>(
  contentType: string,
  ids: Array<string>,
  options: {
    select?: string;
    include?: number;
  } = {},
) {
  const { select, include } = options;
  const client = createContentfulClient();
  const response = await client.getEntries<T>({
    content_type: contentType,
    ...(select && { select }),
    ...(include && { include }),
    'sys.id[in]': ids.join(','),
  });
  if (response.items.length === 0) {
    return [];
  }
  const safeEntry = JSON.parse(response.stringifySafe()) as Omit<
    typeof response,
    'toPlainObject' | 'stringifySafe'
  >;
  // contentful de-duplicates if you have multiple of the same entry id
  // callers expect the same response shape as the input
  // so if we call with ['id1', 'id2', 'id1']
  // we need to include dupe entries
  return ids.map((id) => safeEntry.items.find((x) => x.sys.id === id)) as Array<
    contentful.Entry<T>
  >;
}
