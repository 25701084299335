import type { TypographyProps } from '@opendoor/bricks/system/theme/typography.types';

const typography: {
  [key: string]: TypographyProps;
} = {
  title: {
    fontSize: ['s4', 's5', 's6', 's6'],
    fontWeight: 'bold',
    lineHeight: ['s3', 's4', 's6', 's6'],
  },
  FooterBoxTitle: {
    fontSize: ['s4', 's4', null, 's5'],
    fontWeight: 'bold',
    lineHeight: ['s4', 's4', null, 's5'],
  },
  headerSummary: {
    fontSize: ['s2', null, null, 's3'],
    fontWeight: 'regular',
    lineHeight: ['s2', null, null, 's3'],
  },
  h1: {
    fontSize: ['s4', null, null, 's5'],
    lineHeight: ['s4', null, null, 's5'],
    fontWeight: 'bold',
  },
  h2: {
    fontSize: ['s3', null, null, 's4'],
    lineHeight: ['s3', null, null, 's4'],
    fontWeight: 'bold',
  },
  h3: {
    fontSize: ['s2', null, null, 's3'],
    lineHeight: ['s2', null, null, 's3'],
    fontWeight: 'semibold',
  },
  h4: {
    fontSize: ['s0', null, null, 's2'],
    lineHeight: ['s0', null, null, 's2'],
    fontWeight: 'semibold',
  },
  body: {
    fontSize: ['s1', null, null, 's2'],
    fontWeight: 'regular',
    lineHeight: ['s1', null, null, 's3'],
  },
  spotlightHeader: {
    fontSize: ['s1', null, null, 's2'],
    fontWeight: 'semibold',
    lineHeight: ['s1', null, null, 's3'],
  },
  spotlightAuthor: {
    fontSize: ['s00', null, null, 's00'],
    fontWeight: 'regular',
    lineHeight: ['s00', null, null, 's00'],
  },
  authorName: {
    fontSize: ['s1', null, null, 's1'],
    fontWeight: 'semibold',
    lineHeight: ['s1', null, null, 's1'],
  },
  headerImage: {
    fontSize: ['s1', 's1', 's2', 's2'],
    fontWeight: ['normal', 'normal', 'semibold', 'semibold'],
    lineHeight: ['s1', 's1', 's2', 's2'],
    fontFamily: 'sans',
  },
};

export default typography;
