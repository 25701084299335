import { Box, Text } from '@opendoor/bricks-next';

import { EntryComponent } from '../../cms/entries/entries';
import renderImage from '../../cms/renderer/assets';
import { IImageAsset, IImageAssetFields } from '../../declarations/contentful';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

const GifComponent = (fields: IImageAssetFields) => {
  return (
    <Box key={fields.asset.fields.file?.url || 'backup-id'} marginVertical="$10x">
      <Box
        overflow="hidden"
        width={fields.asset.fields.file.details.image?.width}
        height="100%"
        maxWidth="100%"
        position="relative"
      >
        {renderImage({
          src: fields.asset.fields.file?.url,
          description: fields.description,
          width: fields.asset.fields.file.details.image?.width ?? 100,
          height: fields.asset.fields.file.details.image?.height ?? 200,
        })}
      </Box>
    </Box>
  );
};

const IconComponent = (fields: IImageAssetFields) => {
  return (
    <Box
      display="inline-flex"
      key={fields.asset.fields.file?.url || 'backup-id'}
      marginVertical={5}
    >
      <Box
        overflow="hidden"
        width={fields.asset.fields.file.details.image?.width}
        height={fields.asset.fields.file.details.image?.height}
        position="relative"
      >
        {renderImage({
          src: fields.asset.fields.file?.url,
          description: fields.description,
          width: fields.asset.fields.file.details.image?.width ?? 100,
          height: fields.asset.fields.file.details.image?.height ?? 200,
        })}
      </Box>
    </Box>
  );
};

const ImageComponent = (fields: IImageAssetFields) => {
  const noTitleAndSource = !fields.title?.trim() && !fields.source?.trim();

  return (
    <Box
      width="100%"
      justifyContent="center"
      marginVertical="$6x"
      $largerThanSM={{ marginVertical: '$16x' }}
    >
      <Box
        backgroundColor="$backgroundPrimary"
        minWidth="100%"
        borderRadius="$10x"
        shadow="$default"
        key={fields?.asset?.fields?.file?.url || 'backup-id'}
        flexDirection="column"
        alignItems="center"
      >
        <Box
          borderTopLeftRadius="$10x"
          borderTopRightRadius="$10x"
          borderBottomLeftRadius={noTitleAndSource ? '$10x' : undefined}
          borderBottomRightRadius={noTitleAndSource ? '$10x' : undefined}
          overflow="hidden"
          width="100%"
        >
          {renderImage({
            src: fields?.asset?.fields?.file?.url,
            description: fields?.description,
            width: fields?.asset?.fields?.file?.details?.image?.width ?? 100,
            height: fields?.asset?.fields?.file?.details?.image?.height ?? 200,
          })}
        </Box>
        {!noTitleAndSource && (
          <Box alignItems="center" padding="$6x">
            {fields.title?.trim() && (
              <Text tag="p" typography="$bodyMedium" marginBottom={0}>
                {fields.title}
              </Text>
            )}
            {fields.source?.trim() && (
              <Text tag="p" typography="$bodyMedium" marginTop={0}>
                Source: {fields.source}
              </Text>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

const components = {
  gif: GifComponent,
  icon: IconComponent,
  image: ImageComponent,
};

const renderImageAsset = (entry: IImageAsset) => {
  const fields = entry.fields;

  const width = fields?.asset.fields.file.details.image?.width;
  const height = fields?.asset.fields.file.details.image?.height;

  const imageType =
    (fields?.asset.fields.file.contentType === 'image/gif' && 'gif') ||
    (width && height && width <= 128 && height <= 128 && 'icon') ||
    'image';

  const Component = components[imageType];

  return (
    <EmbeddedEntryWrapper alignment="text" minWidth={200}>
      <Component {...fields} />
    </EmbeddedEntryWrapper>
  );
};

const ImageAsset: EntryComponent<IImageAsset> = {
  render: renderImageAsset,
};

export default ImageAsset;
