import { Document } from '@contentful/rich-text-types';

import { LandingPageV2Props, TopicPageProps } from 'cms';

export const PAGE_ITEMS_LIMIT = 16;

export const PAGINATION_CONTENT_TYPES = [
  'componentRelatedArticles',
  'componentRelatedExternalLinks',
  'lpComponentMulticard',
] as const;

type PaginatedContentType = (typeof PAGINATION_CONTENT_TYPES)[number];

/*
 * Returns an object with:
 * - Key: Content model ID e.g. componentRelatedArticles
 * - Value: Array of sys IDs for this content model type
 */
function createEmbeddedContentToSysIdsMap(document: Document): {
  [key in PaginatedContentType]: Array<string>;
} {
  const initMap = Object.fromEntries(
    PAGINATION_CONTENT_TYPES.map((item: PaginatedContentType) => [item, []]),
  );
  return document?.content?.reduce((result: { [key: string]: Array<string> }, current) => {
    const componentType: string = current?.data?.target?.sys?.contentType?.sys?.id;
    if (
      current?.nodeType === 'embedded-entry-block' &&
      PAGINATION_CONTENT_TYPES?.includes(componentType as PaginatedContentType)
    ) {
      const componentSysId: string = current?.data?.target?.sys?.id;
      result[componentType].push(componentSysId);
    }
    return result;
  }, initMap) as { [key in PaginatedContentType]: Array<string> };
}

/*
 * Topic page currently supports pagination for embedded entry blocks only when
 * the body contains only 1 type of related content (e.g. componentRelatedArticles,
 * componentRelatedExternalLinks). For example, pagination will NOT be shown if
 * the topic page body has 2 related article entries.
 */
export function getPaginationForData(
  props: LandingPageV2Props | TopicPageProps,
  initCurrentPage: number,
) {
  let pagination: {
    showPagination?: boolean;
    currentPage?: number;
    lastPage?: number;
    previousPage?: number;
    nextPage?: number;
  } = {};
  const embeddedContentMap = createEmbeddedContentToSysIdsMap(props.fields.body) || {};
  Object.keys(embeddedContentMap)?.forEach((key) => {
    const contentType: PaginatedContentType = key as PaginatedContentType;
    if (embeddedContentMap[contentType]?.length !== 1) {
      // Filter out any content types that don't have only 1 instance of it
      delete embeddedContentMap[contentType];
    }
  });
  let loaderDataId; // We use the loader data ID to get the total number of items for this content type
  if (Object.keys(embeddedContentMap)?.length === 1) {
    const contentType = Object.keys(embeddedContentMap)[0];
    loaderDataId = embeddedContentMap[contentType as PaginatedContentType][0];
    if (props.idToLoaderData[loaderDataId]) {
      const total: number = props.idToLoaderData[loaderDataId]?.total || 0;
      pagination = getPaginationState(total, initCurrentPage);
    }
  }
  return pagination;
}

export function getPaginationState(totalItems: number, initCurrentPage?: number) {
  const lastPage = Math.ceil(totalItems / PAGE_ITEMS_LIMIT);
  const currentPage =
    initCurrentPage && initCurrentPage > 0 && initCurrentPage <= lastPage ? initCurrentPage : 1;
  return {
    showPagination: totalItems > PAGE_ITEMS_LIMIT,
    currentPage,
    lastPage,
    previousPage: currentPage > 1 ? currentPage - 1 : 0,
    nextPage: currentPage < lastPage ? currentPage + 1 : lastPage,
  };
}

export function getSkipOffset(pageIndex: number) {
  const skipMultiplier = pageIndex === 1 ? 0 : pageIndex - 1;
  const skip = skipMultiplier * PAGE_ITEMS_LIMIT;
  return skip;
}
