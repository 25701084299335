export const defaultEase = [0.55, 0, 0.15, 1];
export const defaultEaseCss = 'cubic-bezier(0.55, 0, 0.15, 1)';
export const show = {
  start: {
    opacity: 0,
    y: 24,
  },
  end: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      type: 'spring',
    },
  },
};
