import { Box, Text } from '@opendoor/bricks-next';
import dynamic from 'next/dynamic';

import { EntryComponent } from '../../cms/entries/entries';
import { ICtaEmailCapture } from '../../declarations/contentful';
import type { EmailInputProps } from '../shared/EmailInput';
import { EmbeddedEntryWrapper } from './EmbeddedEntryWrapper';

const DynamicEmailInput = dynamic<EmailInputProps>(
  () => import('../shared/EmailInput').then((mod) => mod.EmailInput),
  {
    ssr: false,
  },
);

const renderEmailEntry = (entry: ICtaEmailCapture): React.ReactNode => {
  const { fields } = entry;
  return (
    <EmbeddedEntryWrapper alignment="text">
      <Box
        borderRadius="$8x"
        marginBottom="$9x"
        marginTop="$9x"
        paddingVertical="$10x"
        paddingHorizontal="$10x"
        width="100%"
        $largerThanSM={{
          marginBottom: '$10x',
          marginTop: '$10x',
          paddingVertical: '$16x',
          paddingHorizontal: '$16x',
          marginRight: -10,
        }}
        backgroundColor={'$backgroundAccentBrand'}
      >
        <Box alignItems="stretch" display="inline-flex">
          <Text
            tag="h2"
            typography="$subheaderSmall"
            $largerThanSM={{ typography: '$subheaderLarge' }}
            color="$borderStateInactivePrimary"
            marginBottom="$10x"
          >
            {fields.largeTitle}
          </Text>
          <Text
            tag="p"
            typography="$bodyMedium"
            marginBottom="$10x"
            color="$borderStateInactivePrimary"
          >
            {fields.bodyText}
          </Text>
          <DynamicEmailInput campaignName="articles" ctaText={fields.buttonText} />
        </Box>
      </Box>
    </EmbeddedEntryWrapper>
  );
};

const EmailInputComponent: EntryComponent<ICtaEmailCapture> = {
  render: renderEmailEntry,
};

export default EmailInputComponent;
