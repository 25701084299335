// storybook-check-ignore
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { css } from '@emotion/react';
import { Box, Button } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';
import { AnimatePresence, motion } from 'framer-motion';

import { CenteredLayout } from '../StandaloneText';
import { trapFocus } from './trapFocus';

const backdropVariants = {
  hidden: {
    opacity: 0,
    transition: {
      ease: [0.55, 0, 0.15, 1],
      delay: 0.5,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      ease: [0.55, 0, 0.15, 1],
      duration: 0.2,
    },
  },
};

const boxVariants = {
  hidden: {
    y: '100%',
    transition: {
      ease: [0.55, 0, 0.15, 1],
      duration: 0.5,
    },
  },
  visible: {
    y: 0,
    transition: {
      ease: [0.55, 0, 0.15, 1],
      duration: 0.75,
    },
  },
};

interface ISheetContentProps {
  entry: any;
  setIsVisible: (isVisible: boolean) => void;
}

interface ISheetProps extends ISheetContentProps {
  exitComplete: () => void;
  isVisible?: boolean;
}

const SheetContent = ({ entry, setIsVisible }: ISheetContentProps) => {
  const dialogId = `dialog-${Math.floor(Math.random() * 100 + 1)}-${entry.sys.id}`;
  const containerRef = useRef<HTMLDivElement>(null);
  const onComplete = (definition: any) => {
    // Only focus on main sheet container when the visible animation is complete
    // or it messes up the animation
    if (definition === 'visible') {
      containerRef.current?.focus();
    }
  };

  return (
    <Box
      onKeyDown={(e) => trapFocus(e, containerRef)}
      bottom={0}
      display="grid"
      gridTemplate="1fr / 1fr"
      position="fixed"
      top={0}
      left={0}
      overflow="auto"
      right={0}
      p={['80px 16px 0', null, '112px 16px 0']}
      zIndex={3000}
      css={css`
        overscroll-behavior-y: contain;
      `}
    >
      <Box
        onAnimationComplete={(definition: any) => {
          onComplete(definition);
        }}
        as={motion.div}
        variants={boxVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        tabIndex={-1}
        ref={containerRef}
        backgroundColor="white0"
        gridArea="1 / 1 / -1 / -1"
        maxW="1440px"
        mx="auto"
        p={['64px 16px 32px', null, '148px 16px 32px']}
        position="relative"
        zIndex="10"
        role="dialog"
        aria-labelledby={dialogId}
        aria-modal="true"
        outline="0"
        css={css`
          border-radius: ${novo.radii.semiRounded} ${novo.radii.semiRounded} 0 0;
        `}
      >
        <Button
          aria-label="Close"
          analyticsName=""
          backgroundColor="warmgrey300"
          color="warmgrey700"
          width={40}
          height={40}
          minH={40}
          onClick={() => setIsVisible(false)}
          p="0"
          position="absolute"
          right="16px"
          top="16px"
          _hover={{
            backgroundColor: 'warmgrey300',
            color: 'brandBlue600',
          }}
          _active={{
            backgroundColor: 'warmgrey300',
            borderRadius: 'roundedSquare',
            outline: 0,
          }}
          _focus={{
            backgroundColor: 'warmgrey300',
            borderRadius: 'roundedSquare',
            outline: 0,
          }}
          css={css`
            border-radius: ${novo.radii.roundedSquare};
          `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13 3L3 13"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 3L13 13"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Button>
        <CenteredLayout entry={entry} dialogId={dialogId} />
      </Box>
      <Box
        as={motion.div}
        variants={backdropVariants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        bottom={0}
        position="fixed"
        top={0}
        left={0}
        right={0}
        aria-hidden="true"
        zIndex="0"
        onClick={() => setIsVisible(false)}
        css={css`
          background-color: rgba(0, 0, 0, 0.2);
        `}
      ></Box>
    </Box>
  );
};

const Sheet = ({ entry, isVisible, setIsVisible, exitComplete }: ISheetProps) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  useEffect(() => {
    document.body.classList.toggle('has-sheet', isVisible);
  }, [isVisible]);

  const escFunction = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      setIsVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return mounted
    ? createPortal(
        <AnimatePresence initial={false} onExitComplete={() => exitComplete()}>
          {isVisible && (
            <Box key={`sheet-${entry.sys.id}`} as={motion.div}>
              <SheetContent entry={entry} setIsVisible={setIsVisible} />
            </Box>
          )}
        </AnimatePresence>,
        document.body,
      )
    : null;
};

export default Sheet;
