// storybook-check-ignore
import { Box, BoxProps } from '@opendoor/bricks-next';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';

interface WrapperProps extends BoxProps {
  children: React.ReactNode;
  alignment?: string;
  minWidth?: number;
}

export const EmbeddedEntryWrapper = ({ children, alignment = 'full', minWidth }: WrapperProps) => {
  const gridColumn = alignment === 'text' ? '5 / -2' : '2 / -2';

  return (
    <Box
      minWidth={minWidth}
      className="embedded-entry-wrapper"
      flex="unset"
      flexDirection="unset"
      flexShrink="unset"
      flexBasis="unset"
      display="block"
      style={{ gridColumn }}
    >
      <ThemedLite theme="legacy">{children}</ThemedLite>
    </Box>
  );
};
