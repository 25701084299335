import { KeyboardEventHandler, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { Box, getCSSVariable, Image, ImageProps, Link, Text } from '@opendoor/bricks-next';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronDown from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronDown16';
import { novo } from '@opendoor/bricks/theme';

import { Accordion } from 'components/shared/Accordion';

import { ILpComponentMulticardFields } from 'declarations/contentful';

import { ICtaStyles, LPFauxBtn } from '../../landing-pages-v2/shared/Buttons';
import Sheet from '../../landing-pages-v2/shared/Sheet';
import { IComponentThemeOptions } from '../../landing-pages-v2/shared/styles/ComponentTheme';
import { Eyebrow } from '../../landing-pages-v2/shared/Typography';
import { markdownToReactElement } from '../../landing-pages-v2/shared/utils';

const convertMinHeight = (value: ILpComponentMulticardFields['minHeight']): number | undefined => {
  if (!value) return undefined;
  return parseInt(value.replace('px', ''), 10);
};
export interface IMulticardCardDetails {
  eyebrow?: string;
  title?: string;
  subhead?: string;
  desc?: string;
  imageUrl?: string;
  ctaDisplayText?: string;
  ctaStyle?: ICtaStyles;
  ctaSheetLabel?: string;
  ctaSheet?: any;
  componentTheme: IComponentThemeOptions;
  isArched?: boolean;
  isAccordion?: boolean;
  cardStyle?: string;
  cardVerticalAlignment?: string;
  analyticsId?: string;
  redirectUrl?: string;
  cardsLimit?: number;
  columnNumber?: number;
}

export interface IMulticardCardProps<T> {
  id?: string;
  card: T;
  componentTheme: IComponentThemeOptions;
  isArched?: boolean;
  isAccordion?: boolean;
  minHeight?: ILpComponentMulticardFields['minHeight'];
  cardVerticalAlignment?: string;
  columnNumber?: number;
}

export interface ICardSheet {
  card: IMulticardCardDetails;
  isArched?: boolean;
  componentTheme: IComponentThemeOptions;
  cardVerticalAlignment?: string;
  minHeight?: string;
  columnNumber?: number;
}

export const getCardPadding = ({
  imageUrl,
  cardVerticalAlignment,
}: {
  imageUrl?: string;
  cardVerticalAlignment?: string;
}) => {
  // adjust padding if the card has an image, or if the card is vertically center aligned
  return imageUrl
    ? '8px 24px 24px'
    : cardVerticalAlignment === 'center'
    ? '24px'
    : '88px 24px 24px';
};

export const getCardArch = ({
  cardStyle,
  isArched,
}: {
  cardStyle?: string;
  isArched?: boolean;
}) => {
  if (cardStyle === 'Arch') {
    return true;
  }

  return isArched;
};

export const Arch = styled(Image)((props: ICardImage) => ({
  borderRadius: props.isArched
    ? '99em 99em 8em 8em'
    : `${novo.radii.semiRounded} ${novo.radii.semiRounded} 4px 4px`,
}));

interface ICardImage extends Omit<ImageProps, 'variant' | 'orientation' | 'source'> {
  isArched: boolean;
  src: string;
  alt: string;
  columnNumber?: number;
}
export const CardImage = ({ isArched, src, alt, columnNumber, ...props }: ICardImage) => {
  return (
    <Image
      {...props}
      source={{
        uri: src,
        height: 180,
      }}
      flex={1}
      minHeight={160}
      $largerThanMD={{ minHeight: 320 }}
      $largerThanSM={{ minHeight: 200 }}
      height={columnNumber ? (columnNumber > 3 ? 180 : 240) : 180}
      alt={alt}
      marginLeft={-16}
      maxWidth="unset"
      width={'calc(100% + 32px)' as any}
      borderTopLeftRadius={isArched ? '$full' : '$12x'}
      borderTopRightRadius={isArched ? '$full' : '$12x'}
      borderBottomLeftRadius="$2x"
      borderBottomRightRadius="$2x"
    />
  );
};

export const MulticardCard = ({
  id,
  card,
  isArched,
  minHeight,
  componentTheme,
  cardVerticalAlignment,
  isAccordion,
  columnNumber,
}: IMulticardCardProps<IMulticardCardDetails>) => {
  return (card.ctaSheet || card.redirectUrl) && !isAccordion ? (
    <CardSheet
      card={card}
      minHeight={minHeight}
      isArched={getCardArch({ cardStyle: card.cardStyle, isArched })}
      componentTheme={componentTheme}
      cardVerticalAlignment={cardVerticalAlignment}
      columnNumber={columnNumber}
    />
  ) : (
    <Box
      key={id}
      display="flex"
      minHeight={minHeight === 'auto' ? 'auto' : convertMinHeight(minHeight)}
      overflow="hidden"
      borderTopLeftRadius={isArched ? '$full' : '$12x'}
      borderTopRightRadius={isArched ? '$full' : '$12x'}
      borderBottomLeftRadius={'$12x'}
      borderBottomRightRadius={'$12x'}
      paddingTop={card.imageUrl ? '$4x' : cardVerticalAlignment === 'center' ? '$12x' : '$44x'}
      paddingBottom={'$12x'}
      paddingLeft={'$12x'}
      paddingRight={'$12x'}
      backgroundColor={getCSSVariable('$backgroundTertiary', 'color')}
    >
      <MulticardCardDetails
        {...card}
        isArched={getCardArch({ cardStyle: card.cardStyle, isArched })}
        cardVerticalAlignment={cardVerticalAlignment}
        componentTheme={componentTheme}
        redirectUrl={card.redirectUrl}
        isAccordion={isAccordion}
      />
    </Box>
  );
};

const CardSheet = ({
  card,
  isArched,
  componentTheme,
  cardVerticalAlignment,
  columnNumber,
}: ICardSheet) => {
  const [isVisible, setIsVisible] = useState(false);
  const ctaRef = useRef<HTMLAnchorElement>(null);
  // When animation is complete
  const exitComplete = () => {
    if (card.ctaSheet) {
      ctaRef.current?.focus();
    }
  };

  // Make visual buttons work like Buttons
  const onKeyDown: KeyboardEventHandler<HTMLAnchorElement> = (e) => {
    if (card.redirectUrl && !card.ctaSheet) {
      e.stopPropagation();
    } else if (e.key === ' ' || e.key === 'Spacebar') {
      setIsVisible(true);
    }
  };
  const [playHover, setPlayHover] = useState(false);
  return (
    <>
      <Link
        position="relative"
        display="flex"
        flexDirection="column"
        width={'100%'}
        height="100%"
        overflow="hidden"
        cursor="pointer"
        borderTopRightRadius={isArched ? '$full' : `$12x`}
        borderTopLeftRadius={isArched ? '$full' : `$12x`}
        borderBottomLeftRadius="$12x"
        borderBottomRightRadius="$12x"
        paddingTop={card.imageUrl ? '$4x' : cardVerticalAlignment === 'center' ? '$12x' : '$44x'}
        paddingLeft="$12x"
        paddingRight="$12x"
        paddingBottom="$12x"
        aria-label={card.ctaSheetLabel || 'Open dialog'}
        analyticsName={`cosmos-landing-page-multicard-${card.analyticsId}`}
        outlineColor="unset"
        href={!card.ctaSheet && card.redirectUrl ? card.redirectUrl : undefined}
        onPress={() => card.ctaSheet && setIsVisible(true)}
        onPressIn={onKeyDown as any}
        ref={ctaRef}
        outlineWidth={0}
        tabIndex={0}
        backgroundColor="$backgroundTertiary"
        onMouseEnter={() => setPlayHover(true)}
        onMouseLeave={() => setPlayHover(false)}
      >
        <MulticardCardDetails
          {...card}
          isArched={isArched}
          componentTheme={componentTheme}
          cardVerticalAlignment={cardVerticalAlignment}
          analyticsId={card.analyticsId}
          columnNumber={columnNumber}
        />
        <Box
          position="absolute"
          width="100%"
          height="100%"
          scale={playHover ? 2 : 0}
          opacity={0.08}
          bottom={-200}
          left={0}
          zIndex={5}
          key="my-square"
          animation="decelerateFast"
          borderTopRightRadius="$full"
          borderTopLeftRadius="$full"
          transformOrigin="center bottom"
          backgroundColor={'$backgroundInverseStateHoverSecondary'}
        ></Box>
      </Link>
      {card.ctaSheet && (
        <Sheet
          entry={card.ctaSheet}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          exitComplete={exitComplete}
        />
      )}
    </>
  );
};

const AccordionDetails = ({ title, desc }: { title: string; desc: string }) => {
  return (
    <>
      <SelectiveSpritesheet icons={[ChevronDown]} />
      <Accordion
        title={
          <Text
            typography="$subheaderXsmall"
            $largerThanSM={{ typography: '$subheaderSmall' }}
            tag="h4"
            color="$contentPrimary"
            $largerThanMD={{ minHeight: 75 }}
          >
            {title}
          </Text>
        }
        body={
          desc && (
            <Text typography="$bodyMedium" tag="p" color="$contentPrimary">
              {markdownToReactElement(desc)}
            </Text>
          )
        }
        styles={{
          padding: 0,
        }}
      />
    </>
  );
};

const MulticardCardDetails = ({
  eyebrow,
  imageUrl,
  title,
  subhead,
  desc,
  ctaDisplayText,
  ctaStyle,
  isArched,
  isAccordion,
  componentTheme,
  redirectUrl,
  analyticsId,
  columnNumber,
}: IMulticardCardDetails) => {
  const maxCardTitleLen = 48;
  const truncatedTitle =
    (title && title.length > maxCardTitleLen ? `${title.slice(0, maxCardTitleLen)}...` : title) ??
    '';

  return (
    <>
      {imageUrl &&
        (redirectUrl ? (
          <Link
            href={isAccordion ? redirectUrl : undefined}
            aria-label={title || ''}
            analyticsName={`cosmos-landing-page-multicard-${analyticsId}`}
          >
            <CardImage
              isArched={!!isArched}
              src={imageUrl}
              alt={title || 'Card Image'}
              flex={1}
              mb="$16x"
              zIndex={5}
              columnNumber={columnNumber}
            />
          </Link>
        ) : (
          <CardImage
            isArched={!!isArched}
            src={imageUrl}
            alt={title || ''}
            flex={1}
            mb="$16x"
            zIndex={5}
            columnNumber={columnNumber}
          />
        ))}
      <Box flex={1}>
        {isAccordion ? (
          <AccordionDetails title={truncatedTitle} desc={desc || ''} />
        ) : (
          <>
            <Box zIndex={5} display="flex">
              {eyebrow && <Eyebrow mb={2}>{eyebrow}</Eyebrow>}
              <Text typography="$subheaderSmall" tag="h3" color="$contentPrimary" mb={5}>
                {truncatedTitle}
              </Text>
            </Box>
            {subhead && (
              <Text tag="p" typography="$bodyMedium" color="$contentPrimary" fontWeight="$medium">
                {subhead}
              </Text>
            )}
            {desc && (
              <Text typography="$bodyMedium" tag="p" color="$contentPrimary">
                {markdownToReactElement(desc)}
              </Text>
            )}
            {ctaDisplayText && (
              <LPFauxBtn
                componentTheme={componentTheme}
                ctaDisplayText={ctaDisplayText}
                ctaStyle={ctaStyle}
                mt={6}
              />
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default MulticardCard;
