// storybook-check-ignore
import React from 'react';

import { css } from '@emotion/react';
import { Box, Button, Flex, Icon, Link } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ArrowLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowLeft';
import ArrowRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ArrowRight';
import { FontWeight } from '@opendoor/bricks/system/theme';
import { Token } from '@opendoor/bricks/system/token.types';
import { novo } from '@opendoor/bricks/theme';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ILpComponentMulticardFields } from 'declarations/contentful';

import { IComponentThemeOptions } from '../landing-pages-v2/shared/styles/ComponentTheme';
import { GutterLarge } from '../landing-pages-v2/shared/styles/SharedStyles';

export interface ICardCarouselListProps<T> {
  id?: string;
  cards: T[];
  componentTheme: IComponentThemeOptions;
  columnNumber: number;
  isArched?: boolean;
  minHeight?: ILpComponentMulticardFields['minHeight'];
  footerLinkButtonText?: string;
  footerLink?: string;
  renderCard?: (card: T, id?: string) => React.ReactNode;
}

const sliderStyles = css(`
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-color: #000000;
  --swiper-pagination-bullet-color: #000000;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-inactive-color: #000000;
  --swiper-pagination-bullet-inactive-opacity: 0.6;

  .swiper {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.55, 0, 0.15, 1);
  }

  .swiper-slide {
    height: unset;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.55, 0, 0.15, 1);
  }

  .swiper-slide-visible {
    opacity: 1;
  }

  .swiper-initialized {
    opacity: 1;
  }

  .swiper-button-previous,
  .swiper-button-next {
    align-items: center;
    background-color: ${novo.colors.warmgrey100};
    border-radius: ${novo.radii.roundedSquare};
    color: ${novo.colors.warmgrey950};
    cursor: pointer;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    transition: transform 0.3s cubic-bezier(0.55, 0, 0.15, 1), opacity 0.3s cubic-bezier(0.55, 0, 0.15, 1) 0.15s;
    width: 40px;
  }

  .swiper-button-previous:active,
  .swiper-button-next:active {
    transform: scale(0.8);
  }

  .swiper-button-disabled {
    cursor: default;
    opacity: 0.5;
  }

  .swiper-button-disabled:active {
    transform: none;
  }

  .swiper-pagination {
    align-items: center;
    display: flex;
    justify-content: center;
    position: static;
  }

  .swiper-pagination-bullet-active {
    border-radius: 99em;
    transition: all 0.25s cubic-bezier(0.55, 0, 0.15, 1);
    width: 24px;
  }

  .swiper-wrapper {
    display: grid;
    grid-auto-flow: column;
  }
`);

export const CardCarouselV2 = <T,>({
  id,
  cards,
  columnNumber,
  minHeight,
  footerLink,
  footerLinkButtonText,
  renderCard,
}: ICardCarouselListProps<T>) => {
  return (
    <Box pb="64px" css={sliderStyles} boxSizing="content-box" ml="auto" mr="auto" maxW="1440px">
      <SelectiveSpritesheet icons={[ArrowLeft, ArrowRight]} />
      <Swiper
        modules={[Navigation, Pagination]}
        pagination={{
          clickable: true,
          type: 'bullets',
          el: `.swiper-pagination-${id}`,
        }}
        navigation={{
          prevEl: `.swiper-button-previous-${id}`,
          nextEl: `.swiper-button-next-${id}`,
        }}
        slidesPerView={1.1}
        grabCursor
        watchSlidesProgress={true}
        spaceBetween={24}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: columnNumber > 2 ? 3 : 2,
          },
          1200: {
            slidesPerView: columnNumber,
          },
        }}
      >
        {cards?.map((card, idx) => (
          <SwiperSlide key={idx} style={{ minHeight }}>
            {renderCard?.(card, id)}
          </SwiperSlide>
        ))}
      </Swiper>
      <Flex justifyContent="space-between" pt={GutterLarge} alignItems="center">
        <Flex>
          <Button
            variant="icon"
            onClick={() => {}}
            analyticsName=""
            aria-label=""
            className={`swiper-button-previous swiper-button-previous-${id}`}
            type="button"
          >
            <Icon name="arrow-left" size={16} position="relative" />
          </Button>
          <Box width={10} />
          <Button
            variant="icon"
            onClick={() => {}}
            analyticsName=""
            aria-label=""
            className={`swiper-button-next swiper-button-next-${id}`}
            type="button"
          >
            <Icon name="arrow-right" size={16} position="relative" />
          </Button>
        </Flex>
        {footerLink && (
          <Link
            analyticsName="cosmos-multicard-see-all-link"
            aria-label="see all link"
            fontWeight={'500' as Token<FontWeight>}
            color="brandBlue600"
            // Hard coded for now.
            // The footerLink here is slug of the topic page build using landing page v2 content model.
            href={`/articles/topic/${footerLink}`}
          >
            {footerLinkButtonText || 'See all'}
          </Link>
        )}
      </Flex>
    </Box>
  );
};

export default CardCarouselV2;
