export const GutterSmall = '8px';
export const Gutter = '16px';
export const GutterLarge = '32px';
export const OuterMargin = '20px';
export const OuterMarginFlex = ['20px', null, '40px'];

export const StackStyle = {
  '> * + *': {
    'margin-top': '16px',
  },
};
