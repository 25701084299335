import { KeyboardEvent, RefObject } from 'react';

export const trapFocus = (e: KeyboardEvent, containerRef: RefObject<HTMLDivElement>) => {
  if (e.key !== 'Tab') return;

  const containerElements = containerRef.current?.querySelectorAll(
    'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
  );

  const firstElement = containerElements && containerElements[0];
  const lastElement = containerElements && containerElements[containerElements.length - 1];

  // tabbing forwards on the last element (Careers)
  if (!e.shiftKey && document.activeElement === lastElement) {
    (firstElement as HTMLElement).focus();
    return e.preventDefault();
  }

  // tabbing backwards on the first element (Close button)
  if (e.shiftKey && document.activeElement === firstElement) {
    (lastElement as HTMLElement).focus();
    e.preventDefault();
  }
};
