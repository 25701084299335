import { BoxProps, TextProps } from '@opendoor/bricks-next';
import { ColorScheme } from '@opendoor/bricks/system/theme';

export type IBackgroundColors =
  | 'White 0'
  | 'Earth 100'
  | 'Earth 200'
  | 'Brand Blue 600'
  | 'Off White'
  | 'Warm Grey 200'
  | 'Warm Grey 800'
  | 'Warm Grey 950'
  | 'Light'
  | 'Grey'
  | 'Dark';

export interface IComponentThemeOptions {
  bg: ColorScheme;
  color: ColorScheme;
  colorLight: ColorScheme;
  foregroundColor: 'light' | 'dark';
  buttonBg: ColorScheme;
  buttonColor: ColorScheme;
  buttonHoverBg: ColorScheme;
  buttonActiveBg: ColorScheme;
  secondaryButtonBg: ColorScheme;
  secondaryButtonColor: ColorScheme;
  secondaryButtonHoverBg: ColorScheme;
  secondaryButtonActiveBg: ColorScheme;
  tertiaryButtonBg: ColorScheme;
  tertiaryButtonColor: ColorScheme;
  tertiaryButtonHoverColor: ColorScheme;
  tertiaryButtonActiveColor: ColorScheme;
  linkColor: ColorScheme;
  linkAltColor?: ColorScheme;
  linkHoverColor: ColorScheme;
  cardHoverBg?: 'rgba(0, 0, 0, 0.1)' | 'rgba(0, 0, 0, 0.05)';
  cardActiveBg?: 'rgba(0, 0, 0, 0.1)' | 'rgba(0, 0, 0, 0.2)';
}
export interface IComponentThemeConfig {
  'White 0': IComponentThemeOptions;
  'Off White': IComponentThemeOptions;
  'Earth 100': IComponentThemeOptions;
  'Earth 200': IComponentThemeOptions;
  'Brand Blue 600': IComponentThemeOptions;
  'Warm Grey 200': IComponentThemeOptions;
  'Warm Grey 800': IComponentThemeOptions;
  'Warm Grey 950': IComponentThemeOptions;
  Light?: IComponentThemeOptions;
  Dark?: IComponentThemeOptions;
  Grey?: IComponentThemeOptions;
}

export const getComponentThemeColors = (background: IBackgroundColors): IComponentThemeOptions => {
  const theme: IComponentThemeConfig = {
    'White 0': {
      bg: 'white0',
      color: 'warmgrey950',
      colorLight: 'warmgrey700',
      foregroundColor: 'dark',
      buttonBg: 'brandBlue600',
      buttonColor: 'white0',
      buttonHoverBg: 'blue700',
      buttonActiveBg: 'blue900',
      secondaryButtonBg: 'warmgrey950',
      secondaryButtonColor: 'white0',
      secondaryButtonHoverBg: 'warmgrey900',
      secondaryButtonActiveBg: 'warmgrey950',
      tertiaryButtonBg: 'warmgrey200',
      tertiaryButtonColor: 'warmgrey950',
      tertiaryButtonHoverColor: 'warmgrey300',
      tertiaryButtonActiveColor: 'warmgrey200',
      linkColor: 'blue700',
      linkAltColor: 'warmgrey900',
      linkHoverColor: 'blue500',
      cardHoverBg: 'rgba(0, 0, 0, 0.05)',
      cardActiveBg: 'rgba(0, 0, 0, 0.1)',
    },
    'Off White': {
      bg: 'warmgrey100',
      color: 'warmgrey950',
      colorLight: 'warmgrey700',
      foregroundColor: 'dark',
      buttonBg: 'brandBlue600',
      buttonColor: 'white0',
      buttonHoverBg: 'blue700',
      buttonActiveBg: 'blue900',
      secondaryButtonBg: 'warmgrey950',
      secondaryButtonColor: 'white0',
      secondaryButtonHoverBg: 'warmgrey900',
      secondaryButtonActiveBg: 'warmgrey950',
      tertiaryButtonBg: 'warmgrey200',
      tertiaryButtonColor: 'warmgrey950',
      tertiaryButtonHoverColor: 'warmgrey300',
      tertiaryButtonActiveColor: 'warmgrey200',
      linkColor: 'blue700',
      linkAltColor: 'warmgrey900',
      linkHoverColor: 'blue500',
      cardHoverBg: 'rgba(0, 0, 0, 0.05)',
      cardActiveBg: 'rgba(0, 0, 0, 0.1)',
    },
    'Earth 100': {
      bg: 'earth100',
      color: 'warmgrey950',
      colorLight: 'warmgrey700',
      foregroundColor: 'dark',
      buttonBg: 'brandBlue600',
      buttonColor: 'white0',
      buttonHoverBg: 'blue700',
      buttonActiveBg: 'blue900',
      secondaryButtonBg: 'warmgrey950',
      secondaryButtonColor: 'white0',
      secondaryButtonHoverBg: 'warmgrey900',
      secondaryButtonActiveBg: 'warmgrey950',
      tertiaryButtonBg: 'earth300',
      tertiaryButtonColor: 'warmgrey950',
      tertiaryButtonHoverColor: 'earth700',
      tertiaryButtonActiveColor: 'earth700',
      linkColor: 'blue700',
      linkAltColor: 'warmgrey900',
      linkHoverColor: 'blue500',
      cardHoverBg: 'rgba(0, 0, 0, 0.05)',
      cardActiveBg: 'rgba(0, 0, 0, 0.1)',
    },
    'Earth 200': {
      bg: 'earth200',
      color: 'warmgrey950',
      colorLight: 'warmgrey700',
      foregroundColor: 'dark',
      buttonBg: 'brandBlue600',
      buttonColor: 'white0',
      buttonHoverBg: 'blue700',
      buttonActiveBg: 'blue900',
      secondaryButtonBg: 'warmgrey950',
      secondaryButtonColor: 'white0',
      secondaryButtonHoverBg: 'warmgrey900',
      secondaryButtonActiveBg: 'warmgrey950',
      tertiaryButtonBg: 'earth300',
      tertiaryButtonColor: 'warmgrey950',
      tertiaryButtonHoverColor: 'earth700',
      tertiaryButtonActiveColor: 'earth700',
      linkColor: 'blue700',
      linkAltColor: 'warmgrey900',
      linkHoverColor: 'blue500',
      cardHoverBg: 'rgba(0, 0, 0, 0.05)',
      cardActiveBg: 'rgba(0, 0, 0, 0.1)',
    },
    'Brand Blue 600': {
      bg: 'brandBlue600',
      color: 'white0',
      colorLight: 'blue200',
      foregroundColor: 'light',
      buttonBg: 'warmgrey950',
      buttonColor: 'white0',
      buttonHoverBg: 'warmgrey900',
      buttonActiveBg: 'warmgrey950',
      secondaryButtonBg: 'white0',
      secondaryButtonColor: 'brandBlue600',
      secondaryButtonHoverBg: 'blue50',
      secondaryButtonActiveBg: 'blue100',
      tertiaryButtonBg: 'blue500',
      tertiaryButtonColor: 'white0',
      tertiaryButtonHoverColor: 'blue700',
      tertiaryButtonActiveColor: 'blue900',
      linkColor: 'white0',
      linkAltColor: 'white0',
      linkHoverColor: 'blue50',
      cardHoverBg: 'rgba(0, 0, 0, 0.1)',
      cardActiveBg: 'rgba(0, 0, 0, 0.2)',
    },
    'Warm Grey 200': {
      bg: 'warmgrey200',
      color: 'warmgrey950',
      colorLight: 'warmgrey700',
      foregroundColor: 'dark',
      buttonBg: 'brandBlue600',
      buttonHoverBg: 'blue700',
      buttonActiveBg: 'blue900',
      buttonColor: 'white0',
      secondaryButtonBg: 'warmgrey950',
      secondaryButtonColor: 'white0',
      secondaryButtonHoverBg: 'warmgrey900',
      secondaryButtonActiveBg: 'warmgrey950',
      tertiaryButtonBg: 'warmgrey300',
      tertiaryButtonColor: 'warmgrey950',
      tertiaryButtonHoverColor: 'warmgrey100',
      tertiaryButtonActiveColor: 'warmgrey300',
      linkColor: 'blue700',
      linkAltColor: 'warmgrey900',
      linkHoverColor: 'blue500',
      cardHoverBg: 'rgba(0, 0, 0, 0.05)',
      cardActiveBg: 'rgba(0, 0, 0, 0.1)',
    },
    'Warm Grey 800': {
      bg: 'warmgrey800',
      color: 'white0',
      colorLight: 'warmgrey300',
      foregroundColor: 'light',
      buttonBg: 'warmgrey950',
      buttonColor: 'white0',
      buttonHoverBg: 'warmgrey900',
      buttonActiveBg: 'warmgrey900',
      secondaryButtonBg: 'white0',
      secondaryButtonColor: 'warmgrey950',
      secondaryButtonHoverBg: 'blue50',
      secondaryButtonActiveBg: 'blue100',
      tertiaryButtonBg: 'warmgrey700',
      tertiaryButtonColor: 'white0',
      tertiaryButtonHoverColor: 'warmgrey900',
      tertiaryButtonActiveColor: 'warmgrey700',
      linkColor: 'white0',
      linkAltColor: 'white0',
      linkHoverColor: 'blue50',
      cardHoverBg: 'rgba(0, 0, 0, 0.1)',
      cardActiveBg: 'rgba(0, 0, 0, 0.2)',
    },
    'Warm Grey 950': {
      bg: 'warmgrey950',
      color: 'white0',
      colorLight: 'warmgrey700',
      foregroundColor: 'light',
      buttonBg: 'brandBlue600',
      buttonColor: 'white0',
      buttonHoverBg: 'blue700',
      buttonActiveBg: 'blue900',
      secondaryButtonBg: 'white0',
      secondaryButtonColor: 'warmgrey950',
      secondaryButtonHoverBg: 'blue50',
      secondaryButtonActiveBg: 'blue100',
      tertiaryButtonBg: 'warmgrey900',
      tertiaryButtonColor: 'white0',
      tertiaryButtonHoverColor: 'warmgrey700',
      tertiaryButtonActiveColor: 'warmgrey900',
      linkColor: 'white0',
      linkAltColor: 'white0',
      linkHoverColor: 'blue50',
      cardHoverBg: 'rgba(0, 0, 0, 0.1)',
      cardActiveBg: 'rgba(0, 0, 0, 0.2)',
    },
  };

  const currentTheme = theme[background] ?? theme['White 0'];

  return {
    bg: currentTheme.bg,
    color: currentTheme.color,
    colorLight: currentTheme.colorLight,
    foregroundColor: currentTheme.foregroundColor,
    buttonBg: currentTheme.buttonBg,
    buttonColor: currentTheme.buttonColor,
    buttonHoverBg: currentTheme.buttonHoverBg,
    buttonActiveBg: currentTheme.buttonActiveBg,
    secondaryButtonBg: currentTheme.secondaryButtonBg,
    secondaryButtonColor: currentTheme.secondaryButtonColor,
    secondaryButtonHoverBg: currentTheme.secondaryButtonHoverBg,
    secondaryButtonActiveBg: currentTheme.secondaryButtonActiveBg,
    tertiaryButtonBg: currentTheme.tertiaryButtonBg,
    tertiaryButtonColor: currentTheme.tertiaryButtonColor,
    tertiaryButtonHoverColor: currentTheme.tertiaryButtonHoverColor,
    tertiaryButtonActiveColor: currentTheme.tertiaryButtonActiveColor,
    linkColor: currentTheme.linkColor,
    linkAltColor: currentTheme.linkAltColor || currentTheme.linkColor,
    linkHoverColor: currentTheme.linkHoverColor,
    cardHoverBg: currentTheme.cardHoverBg,
    cardActiveBg: currentTheme.cardActiveBg,
  };
};

export enum BNColorThemes {
  Light = 'Light',
  Dark = 'Dark',
  Grey = 'Grey',
}

type ICommonThemeConfig = {
  contentPrimaryAccent: TextProps['color'];
  bgAccentBrand: BoxProps['backgroundColor'];
  bgHoverAccentBrand: BoxProps['backgroundColor'];
  buttonLabelPrimary: TextProps['color'];
  linkColor: TextProps['color'];
  linkHover: TextProps['color'];
};

export interface IBNComponentThemeConfig extends ICommonThemeConfig {
  bgPrimary: BoxProps['backgroundColor'];
  contentPrimary: TextProps['color'];
  bgPrimaryLight: BoxProps['backgroundColor'];
}

/**
 * Function to determine the BricksNext theme colors for components based on the background color value.
 * @param background - The type of background: 'Dark', 'Grey', or 'Light'.
 * @returns An object containing the IBNComponentThemeConfig.
 */
export const getBNComponentThemeColors = (background: BNColorThemes): IBNComponentThemeConfig => {
  // Theme colors that are same for all the varients
  const commonThemeConfig: ICommonThemeConfig = {
    contentPrimaryAccent: '$contentTertiary',
    bgAccentBrand: '$backgroundAccentBrand',
    bgHoverAccentBrand: '$backgroundStateHoverAccentBrand',
    buttonLabelPrimary: '$contentInversePrimary',
    linkColor: '$backgroundAccentBrand',
    linkHover: '$backgroundStateHoverAccentBrand',
  };

  switch (background) {
    case 'Dark':
      return {
        ...commonThemeConfig,
        bgPrimary: '$backgroundInversePrimary',
        contentPrimary: '$contentInversePrimary',
        bgPrimaryLight: '$backgroundInverseStateHoverSecondary',
      };
    case 'Grey':
      return {
        ...commonThemeConfig,
        bgPrimary: '$backgroundTertiary',
        contentPrimary: '$contentPrimary',
        bgPrimaryLight: '$backgroundStateHoverPrimary',
      };
    case 'Light':
    default:
      return {
        ...commonThemeConfig,
        bgPrimary: '$backgroundPrimary',
        contentPrimary: '$contentPrimary',
        bgPrimaryLight: '$backgroundStateHoverPrimary',
      };
  }
};
